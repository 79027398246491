<template>
    <div style="width:100%;height:100%">
        <el-dialog :visible.sync="dialogFormVisible"
        @opened="openDialog()"
        :before-close="beforeClose"
        width="300px"
        height="400px">
        <div slot="title" style="text-align:left;padding:12px 15px 0 0;font-size:14px">请完善信息，我们会尽快核实信息与您取得联系。</div>
            <el-form :model="formData" :rules="validRulesForm" ref="form">
                <i class="el-icon-mobile-phone"></i><span style="padding-left: 5px">手机号码</span>
                <el-form-item prop="tel">
                    <el-input v-model="formData.tel" placeholder="请填写您的手机号码"></el-input>
                </el-form-item>
                <i class="el-icon-s-custom"></i><span style="padding-left: 5px">企业名称</span>
                <el-form-item prop="name">
                    <el-input v-model="formData.name" placeholder="请填写完整企业名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="footer">
                <el-button type="info" style="width:150px" @click="onSmbit" round>立即提交</el-button>
            </div>
            </el-dialog>
    </div>
</template>

<script>
export default {
    name:"", 
    props:{
        dialogFormVisible:{
            type:Boolean,
            default:false
        }
    },
    components: {

    },
    methods: {
        async onSmbit() {
            let result = await this.$refs.form.validate().catch(result => result)
                if (!result) {
                    return false;
                }
                this.$emit('send',false)
        },
        openDialog() {
            this.formData = {
                tel:'',
                name:''
            }
            this.$refs.form.resetFields();
        },
        beforeClose() {
            this.$emit('send',false)
        }
    },
    data() {
        return {
            formData:{
                tel:'',
                name:''
            },
            validRulesForm: {
                    name: [{required: true, message: '请输入完整名称', trigger: 'change'}],
                    tel: [
                        {required: true, message: '请输入电话号码', trigger: 'change'},
                        {
                            pattern: /^((0\d{2,3}-\d{7,8})|(1[3|4|5|6|7|8|9][0-9]{9}))$/,
                            message: '座机号码[xxx-xxxxxxxx] 或 手机号码',
                            trigger: 'change'
                        }
                    ]
                },
        }
    },
}
</script>

<style scoped lang="less">
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
