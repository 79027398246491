<template>
    <div id="dom" class="body">
        <header>
            <img class="navImg" src="@/assets/img/u0.jpg" alt="">
            <div class="navRight">
                <div style="display:flex">
                    <img src="@/assets/img/u8.svg" alt="">
                    <img src="@/assets/img/u9.svg" alt="">
                </div>
                <div>185-0716-9976</div>
            </div>
        </header>
        <section class="navs">
            <ul>
                <router-link to="/home"><li style="margin-left: 0;" :class="active === 1?'active':''">首页</li></router-link>
                <router-link to="/product"><li :class="active === 2?'active':''">产品</li></router-link>
                <router-link to="/case"><li :class="active === 3?'active':''">案例</li></router-link>
                <!-- <li :class="active === 4?'active':''" >资源</li>
                <li :class="active === 5?'active':''" >招聘</li>
                <li :class="active === 6?'active':''" style="position: absolute;right:20px;width:100px">关于我们</li> -->
            </ul>
        </section>
        <main>
            <el-tabs v-model="activeName">
                <el-tab-pane name="1">
                    <span slot="label"><img style="height:20px" src="@/assets/img/u54.svg" v-show="activeName === '1'"> EDP快开平台</span>
                    <section style="display:flex">
                        <div class="linknav">
                            <div style="font-size:30px;width:90%;margin:0 auto;line-height:50px">低代码配置化快速开发工具</div>
                            <div style="font-size:20px;width:90%;margin:0 auto">让企业自主开发管理软件成为可能</div>
                            <el-button style="position: absolute;bottom: 20px;right: 50px;" type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </div>
                        <img style="width:550px;height: 280px;" src="@/assets/img/u53.jpg">
                    </section>
                    <section style="display:flex">
                        <div style="flex:4">
                            <div class="textAroud">
                                <h3>开发：简化开发</h3>
                                <p>
                                    通过低代码配置化工具，简化开发工作，降低人员介入门槛。开发人员只需关注业务规则的实现方式，减少业务无关的功能开发
                                </p>
                            </div>
                            <div class="textAroud">
                                <h3 style="color: #FDB717;">安全：贯穿始终</h3>
                                <p>
                                    采用数据隔离、安全网关、加密集成技术，保障系统和数据的安全
                                </p>
                            </div>
                        </div>
                        <img style="flex:2;height:220px" src="@/assets/img/u58.png">
                        <div style="flex:4">
                            <div class="textAroud">
                                <h3 style="text-align: left;color: #31A8E0;">维护：降低难度</h3>
                                <p>
                                    通过业务配置规则，缩短需求变更处理周期，降低代码维护的难度和对成本的影响，提升系统交付的质量
                                </p>
                            </div>
                            <div class="textAroud">
                                <h3 style="text-align: left;color: #ED5724;">积累：技术积累</h3>
                                <p>
                                    通过对不同项目应用的积累，将不同项目中共性的技术集成到基础框架中，不断的完善基础框架功能
                                </p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <img style="width:100%" src="@/assets/img/u67.png">
                        <img style="width:100%"  src="@/assets/img/u68.png">
                        <img style="width:100%"  src="@/assets/img/u69.png">
                        <span style="display: flex;justify-content: flex-end;">
                            <el-button type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </span>
                        
                    </section>
                </el-tab-pane>
                <el-tab-pane name="2">
                    <span slot="label"><img style="height:20px" src="@/assets/img/u54.svg" v-show="activeName === '2'"> DEP数据交换平台</span>
                    <section style="display:flex">
                        <div class="linknav">
                            <div style="font-size:30px;width:90%;margin:0 auto;line-height:50px">可视化异构数据交换</div>
                            <div style="font-size:20px;width:90%;margin:0 auto">让企业数据交换集成发挥价值</div>
                            <el-button style="position: absolute;bottom: 20px;right: 50px;" type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </div>
                        <img style="width:550px;height: 280px;" src="@/assets/img/u111.png">
                    </section>
                    <section>
                        <ul style="list-style: none;">
                            <li style="display:flex;color:#8dc356;padding: 15px 0">
                                <img style="width:50px;height: 30px;" src="@/assets/img/u105.svg">
                                <span style="font-size:22px;line-height:30px;padding-left:20px">服务于企业数据集成，为各异构系统的数据整合提供支撑</span>
                            </li>
                            <li style="display:flex;color:#79c6eb;padding: 15px 0">
                                <img style="width:50px;height: 30px;" src="@/assets/img/u106.svg">
                                <span style="font-size:22px;line-height:30px;padding-left:20px">服务于企业数据集成，为各异构系统的数据整合提供支撑</span>
                            </li>
                            <li style="display:flex;color:#fdb717;padding: 15px 0">
                                <img style="width:50px;height: 30px;" src="@/assets/img/u107.svg">
                                <span style="font-size:22px;line-height:30px;padding-left:20px">服务于企业数据集成，为各异构系统的数据整合提供支撑</span>
                            </li>
                            <li style="display:flex;color:#ed5724;padding: 15px 0">
                                <img style="width:50px;height: 30px;" src="@/assets/img/u108.svg">
                                <span style="font-size:22px;line-height:30px;padding-left:20px">服务于企业数据集成，为各异构系统的数据整合提供支撑</span>
                            </li>
                            <li style="display:flex;color:#6060b0;padding: 15px 0">
                                <img style="width:50px;height: 30px;" src="@/assets/img/u109.svg">
                                <span style="font-size:22px;line-height:30px;padding-left:20px">服务于企业数据集成，为各异构系统的数据整合提供支撑</span>
                            </li>
                        </ul>
                       
                    </section>
                    <section>
                        <img style="width:100%" src="@/assets/img/u111.png">
                        <img style="width:100%"  src="@/assets/img/u110.png">
                        <img style="width:100%"  src="@/assets/img/u112.png">
                        <span style="display: flex;justify-content: flex-end;">
                            <el-button type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </span>
                        
                    </section>
                </el-tab-pane>
                <el-tab-pane name="3">
                    <span slot="label"><img style="height:20px" src="@/assets/img/u54.svg" v-show="activeName === '3'"> 工作流引擎BPM</span>
                    <section style="display:flex">
                        <div class="linknav">
                            <div style="font-size:30px;width:90%;margin:0 auto;line-height:50px">让管理流程与开发更加协同</div>
                            <div style="font-size:20px;width:90%;margin:0 auto">可视化配置业务流程</div>
                            <el-button style="position: absolute;bottom: 20px;right: 50px;" type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </div>
                        <img style="width:550px;height: 280px;" src="@/assets/img/u14.png">
                    </section>
                    <section>
                        <img style="width:100%" src="@/assets/img/u142.png">
                        <img style="width:100%"  src="@/assets/img/u143.png">
                        <img style="width:100%"  src="@/assets/img/u144.png">
                        <span style="display: flex;justify-content: flex-end;">
                            <el-button type="warning" @click="dialogFormVisible = true"> 》 》产品咨询</el-button>
                        </span>
                        
                    </section>
                </el-tab-pane>
            </el-tabs>
        </main>
        <footer>
            <div class="text">相互相成 真心服务</div>
            <img src="@/assets/img/u23.png" />
            <div>
            <span style="text-align: left;">关键字：低代码 配置化 开发工具 开发平台 数据交换 可视化 异构数据库交换平台 企业管理软件</span>
            <span style="text-align: right;">武汉力源达信息技术有限公司</span>
            <span style="text-align: center;font-size: 10px;line-height: 20px;">copyright © 武汉力源达信息技术有限公司版权所有 | ICP主体备案号：鄂ICP备19030060号 | 备案状态：正常 </span>
            </div>
        </footer>
        <section style="position: fixed;right: 5%;top:40%;">
          <suspension  />
        </section>
        <el-button   type="info" icon="el-icon-caret-top" 
                  circle class="backTop" 
                  v-show="flag_scroll" @click="backTop()"></el-button>
        <lyd-dialog :dialogFormVisible="dialogFormVisible" @send="sendClose" />
    </div>
</template>

<script>
import lydDialog from '@/components/common/lydDialog'
import suspension from '@/components/common/suspension'
export default {
    name:"product", 
    components:{
        lydDialog,
        suspension
    },
    mounted() {
      //滚动监听
      window.addEventListener('scroll', this.handleScroll, true)
    },
    //销毁滚动监听
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        sendClose(val) {
            this.dialogFormVisible = val
        },
        //置顶
        backTop(){
            document.getElementById('dom').scrollIntoView()
        },
        //监听滚动事件
        handleScroll(){
            if(document.body.scrollTop > 500){
                this.flag_scroll = true
            }else {
                this.flag_scroll = false
            }
        },
    },
    data() {
        return {
            active:2,
            activeName:'1',
            flag_scroll: false,
            dialogFormVisible:false,
        }
    },
}
</script>

<style scoped lang="less">
.body {
    margin: 0px;
    background-image: none;
    position: relative;
    left: 0px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
header {
    position: relative;
    display: flex;
    .navImg {
        width: 100%;
    }
    .navRight {
        position: absolute;
        right: 0;
        img {
        margin-right: 30px;
        }
    }
}
footer {
    position: relative;
    width: 100%;
    // height: 200px;
    .text {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translate(-50%,0);
        font-size: 35px;
        text-align: center;
        margin: 0 auto;
        width: 300px;
        background: rgba(215, 215, 215, 0);
    }
    img {
        width: 100%;
    }
    div {
        display: flex;
        flex-direction: column;
        background: rgba(215, 215, 215, 1);
        margin-top: -5px;
        padding: 0 20px;
        span {
        line-height: 40px;
        }
    }
}
.navs {
    width: 100%;
    background: rgba(0, 147, 208, 1);
        ul {
            display: flex;
            list-style: none;
            height: 45px;
            line-height: 45px;
            margin-bottom: 5px;
            color: #fff;
            position: relative;
            li {  
            width: 60px;
            margin-left: 70px;
            text-align: center;
            cursor: pointer;
            &:hover {
                width: 60px;
                margin-top: 2.5px;
                height: 40px;
                line-height: 40px;
                background: #fff;
                border-radius: 5px;
                font-size: 20px;
                color: rgba(0, 147, 208, 1);
            }

        }
    }
}
.active {
    width: 60px;
    margin-top: 2.5px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-radius: 5px;
    font-size: 20px;
    color: #0093d0;
}
.linknav {
    flex: 1;
    height: 280px;
    padding-left: 10px;
    background-color: rgba(212, 239, 248, 0.7);
    color: rgb(89 109 181);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
}
.textAroud {
    width: 100%;
    h3 {
        text-align: right;
        color: #7FBC41;
        padding-right: 15px;
    }
}
a {
    color: #fff!important;
    text-decoration: none!important;
}
 
.router-link-active {
    color: #fff!important;
    text-decoration: none!important;
}
.backTop {
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 99;
}
</style>
